var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { scrollable: "", persistent: "", width: "600" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _vm.isPlaylist
                ? _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        { staticClass: "btn btn-s", attrs: { text: "" } },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [_vm._v(_vm._s(_vm.$t("Contents Edit")) + " ")]
                  )
                : _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          staticClass: "btn ml-2",
                          attrs: {
                            disabled: _vm.btnDisabledEditMedia,
                            text: ""
                          }
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [_vm._v(_vm._s(_vm.$t("Edit")))]
                  )
            ]
          }
        }
      ]),
      model: {
        value: _vm.editMediaVisible,
        callback: function($$v) {
          _vm.editMediaVisible = $$v
        },
        expression: "editMediaVisible"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "popup" },
        [
          _c(
            "div",
            { staticClass: "popup-titbox _between" },
            [
              _c("v-card-title", [
                _vm.mediaType === "video"
                  ? _c("h3", { staticClass: "page-title-bar" }, [
                      _c("i", { staticClass: "ico ico-media-list" }),
                      _vm._v(_vm._s(_vm.$t("Edit Video")) + " ")
                    ])
                  : _vm._e(),
                _vm.mediaType === "image"
                  ? _c("h3", { staticClass: "page-title-bar" }, [
                      _c("i", { staticClass: "ico ico-media-list" }),
                      _vm._v(_vm._s(_vm.$t("Edit Image")) + " ")
                    ])
                  : _vm._e()
              ])
            ],
            1
          ),
          _c("v-card-text", [
            _c("div", { staticClass: "product_detail" }, [
              _c("table", [
                _c("colgroup", [
                  _c("col", { staticStyle: { width: "120px" } }),
                  _c("col")
                ]),
                _c("tr", [
                  _c("td", { staticClass: "tdMediaClass" }, [
                    _vm._v(_vm._s(_vm.$t("Media Name")))
                  ]),
                  _c(
                    "td",
                    [
                      _c("v-text-field", {
                        attrs: { outlined: "", dense: "" },
                        model: {
                          value: _vm.formData.media_name,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "media_name", $$v)
                          },
                          expression: "formData.media_name"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("tr", [
                  _c("td", { staticClass: "tdCategoryClass" }, [
                    _vm._v(_vm._s(_vm.$t("Category")))
                  ]),
                  _c(
                    "td",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { md: "6" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  disabled:
                                    _vm.selectCategory === "" ? false : true,
                                  outlined: "",
                                  dense: ""
                                },
                                model: {
                                  value: _vm.formData.category,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formData, "category", $$v)
                                  },
                                  expression: "formData.category"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { md: "6" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  items: _vm.category,
                                  outlined: "",
                                  dense: ""
                                },
                                model: {
                                  value: _vm.selectCategory,
                                  callback: function($$v) {
                                    _vm.selectCategory = $$v
                                  },
                                  expression: "selectCategory"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]),
                _c("tr", [
                  _c("td", [_vm._v(_vm._s(_vm.$t("Media File")))]),
                  _c(
                    "td",
                    { staticClass: "mediafileClass" },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticStyle: { display: "none" } },
                            [
                              _vm.mediaType === "video"
                                ? _c("v-file-input", {
                                    ref: "media_file",
                                    attrs: {
                                      "prepend-icon": "",
                                      accept: "video/*"
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.fileInput($event, "video")
                                      }
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "prepend-inner",
                                          fn: function() {
                                            return undefined
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      false,
                                      2189158210
                                    ),
                                    model: {
                                      value: _vm.uploadFile,
                                      callback: function($$v) {
                                        _vm.uploadFile = $$v
                                      },
                                      expression: "uploadFile"
                                    }
                                  })
                                : _vm._e(),
                              _vm.mediaType === "image"
                                ? _c("v-file-input", {
                                    ref: "media_file",
                                    attrs: {
                                      "prepend-icon": "",
                                      accept: "image/*"
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.fileInput($event, "image")
                                      }
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "prepend-inner",
                                          fn: function() {
                                            return undefined
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      false,
                                      2189158210
                                    ),
                                    model: {
                                      value: _vm.uploadFile,
                                      callback: function($$v) {
                                        _vm.uploadFile = $$v
                                      },
                                      expression: "uploadFile"
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            [
                              _c("v-text-field", {
                                attrs: { readonly: "" },
                                on: { click: _vm.fileClick },
                                scopedSlots: _vm._u([
                                  {
                                    key: "prepend-inner",
                                    fn: function() {
                                      return [
                                        _c("i", {
                                          staticClass: "ico ico-upload-file"
                                        })
                                      ]
                                    },
                                    proxy: true
                                  }
                                ]),
                                model: {
                                  value: _vm.filename,
                                  callback: function($$v) {
                                    _vm.filename = $$v
                                  },
                                  expression: "filename"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]),
                _c("tr", [
                  _c("td", [_vm._v(_vm._s(_vm.$t("Explain")))]),
                  _c(
                    "td",
                    [
                      _c("v-textarea", {
                        staticClass: "tdExplainClass",
                        attrs: {
                          "no-resize": "",
                          rows: "3",
                          outlined: "",
                          dense: ""
                        },
                        model: {
                          value: _vm.formData.explain,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "explain", $$v)
                          },
                          expression: "formData.explain"
                        }
                      })
                    ],
                    1
                  )
                ])
              ])
            ])
          ]),
          _c(
            "v-card-actions",
            { staticClass: "d-flex justify-center" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "btn",
                  attrs: { text: "", icon: "" },
                  on: { click: _vm.handleEditClick }
                },
                [_vm._v(_vm._s(_vm.$t("Edit")))]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "btn",
                  attrs: { text: "", icon: "" },
                  on: { click: _vm.cancelEditMedia }
                },
                [_vm._v(_vm._s(_vm.$t("Cancel")))]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "" },
          model: {
            value: _vm.isLoading,
            callback: function($$v) {
              _vm.isLoading = $$v
            },
            expression: "isLoading"
          }
        },
        [
          _c("v-progress-circular", {
            staticStyle: { width: "100%" },
            attrs: { indeterminate: "", size: 50, width: 7, color: "#ddd" }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }