<template>
  <v-dialog
    v-model="editMediaVisible"
    scrollable persistent
    width="600"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-if="isPlaylist" text class="btn btn-s" v-bind="attrs" v-on="on"
        >{{ $t("Contents Edit") }}
      </v-btn>
      <v-btn
        v-else
        :disabled="btnDisabledEditMedia"
        class="btn ml-2"
        v-bind="attrs"
        v-on="on"
        text
        >{{ $t("Edit") }}</v-btn
      >
    </template>

    <v-card class="popup">
      <div class="popup-titbox _between">
        <v-card-title>
        <h3 v-if="mediaType === 'video'" class="page-title-bar">
          <i class="ico ico-media-list"></i>{{ $t("Edit Video") }}
        </h3>
        <h3 v-if="mediaType === 'image'" class="page-title-bar">
          <i class="ico ico-media-list"></i>{{ $t("Edit Image") }}
        </h3>
      </v-card-title>
      </div>
      <v-card-text>
      <!-- <div class="custom-scrollBox"> -->
        <div class="product_detail">
          <table>
            <colgroup>
              <col style="width: 120px" />
              <col />
            </colgroup>
            <tr>
              <td class="tdMediaClass">{{$t('Media Name')}}</td>
              <td>
                <v-text-field v-model="formData.media_name" outlined dense></v-text-field>
              </td>
            </tr>
            <tr>
              <td class="tdCategoryClass">{{$t('Category')}}</td>
              <td>
                <v-row>
                  <v-col md="6">
                    <v-text-field
                      :disabled="selectCategory === '' ? false : true"
                      v-model="formData.category"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col md="6">
                    <v-select
                      :items="category"
                      outlined
                      dense
                      v-model="selectCategory"
                    ></v-select>
                  </v-col>
                </v-row>
              </td>
            </tr>
            <tr>
              <td>{{$t('Media File')}}</td>
              <td class="mediafileClass">
                <v-row>
                  <v-col style="display: none">
                    <v-file-input
                      v-if="mediaType === 'video'"
                      @change="fileInput($event, 'video')"
                      v-model="uploadFile"
                      ref="media_file"
                      prepend-icon=""
                      accept="video/*"
                    >
                      <template v-slot:prepend-inner> </template
                    ></v-file-input>
                    <v-file-input
                      v-if="mediaType === 'image'"
                      @change="fileInput($event, 'image')"
                      v-model="uploadFile"
                      ref="media_file"
                      prepend-icon=""
                      accept="image/*"
                    >
                      <template v-slot:prepend-inner> </template
                    ></v-file-input>
                  </v-col>
                  <v-col>
                    <v-text-field
                      readonly
                      @click="fileClick"
                      v-model="filename"
                    >
                      <template v-slot:prepend-inner>
                        <!-- <div @click="uploadFile"> -->
                        <i class="ico ico-upload-file"></i>
                        <!-- </div> -->
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
              </td>
            </tr>
            <tr>
              <td>{{$t('Explain')}}</td>
              <td>
                <v-textarea
                  no-resize
                  rows="3"
                  outlined
                  dense
                  class="tdExplainClass"
                  v-model="formData.explain"
                ></v-textarea>
              </td>
            </tr>
          </table>
        </div>
      <!-- </div> -->
    </v-card-text>
      <v-card-actions class="d-flex justify-center">
        <v-btn @click="handleEditClick" class="btn" text icon>{{
          $t("Edit")
        }}</v-btn>
        <v-btn @click="cancelEditMedia" class="btn" text icon>{{
          $t("Cancel")
        }}</v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="isLoading" persistent>
      <v-progress-circular
        style="width: 100%"
        indeterminate
        :size="50"
        :width="7"
        color="#ddd"
      ></v-progress-circular>
    </v-dialog>
  </v-dialog>
</template>

<script>
import EventBus from '@/plugins/eventBus'
import dicamoApi from '@/lcd/plugins/dicamoApi'
// import 'vue-loading-overlay/dist/vue-loading.css'
// import commons from '@/plugins/commons'

export default {
  props: {
    btnDisabledEditMedia: Boolean,
    user: Object,
    idx_media: String,
    mediaType: String,
    isPlaylist: Boolean
  },
  created () {},
  computed: {
    // getFilename () {
    //   this.upload.name
    // },
    mediaData () {
      if (this.idx_media > 0) {
        return this.getItemMedia()
      } else {
        return null
      }
    }
  },
  mounted () {},
  data () {
    return {
      isLoading: false,
      fullPage: true,
      uploadFile: null,
      category: [],
      editMediaVisible: false,
      filename: '',
      formData: {
        media_name: '',
        category: '',
        mediaFile: null,
        explain: ''
      },
      selectCategory: ''
    }
  },
  methods: {
    fileClick () {
      this.$refs.media_file.$refs.input.click()
      // this.formData.fileName = this.formData.mediaFile.name
      // this.formData.file_name = this.formData.media_file.name
    },
    fileInput (e, type) {
      if (!e) return

      this.filename = this.uploadFile.name
      const filename = String(e.name).split('.')
      if (type === 'video') {
        if (String(filename[filename.length - 1]).toLowerCase() !== 'mp4') {
          this.formData.file = null
          this.filename = ''
          EventBus.$emit('messageAlert', this.$t('Invalid file extension'))
        }
      } else if (type === 'image') {
        if (
          String(filename[filename.length - 1]).toLowerCase() !== 'jpg' &&
          String(filename[filename.length - 1]).toLowerCase() !== 'png'
        ) {
          this.formData.file = null
          this.filename = ''
          EventBus.$emit('messageAlert', this.$t('Invalid file extension'))
        }
      }
    },
    async handleEditClick () {
      if (!this.uploadFile) {
        this.editItemMedia(-1)
        return
      }
      if (String(this.uploadFile.type).indexOf('image') > -1) {
        this.editItemMedia(0)
      }
      var video = document.createElement('video')
      const vue = this
      video.src = window.URL.createObjectURL(this.uploadFile)

      video.addEventListener('loadedmetadata', function () {
        const duration = video.duration
        vue.editItemMedia(duration)
      })

      video.onload = () => {
        window.URL.revokeObjectURL(this.src)
      }
    },
    cancelEditMedia () {
      this.editMediaVisible = false
      this.formData = []
      this.uploadFile = null
    },
    convertCategory (items) {
      const list = []
      items.forEach(function (value) {
        list.push(value.category)
      })
      this.category = list
    },
    getCategory () {
      const form = new FormData()

      form.append('company', this.$store.state.auth.user.company)
      form.append('store', this.$store.state.dataStore.selectedStore.code)
      form.append('timezone', this.$store.state.dataStore.timezone)
      form.append('type', 'category')

      this.$utils
        .callAxiosWithBody(
          dicamoApi.requests.getCategory.method,
          dicamoApi.requests.getCategory.url,
          form
        )
        .then((res) => {
          this.convertCategory(res.data.data)
        })
        .catch((error) => {
          EventBus.$emit('messageAlert', this.$t('Something went wrong'))
          console.debug(`Failed to update a media error: ${error}`)
        })
        .finally(() => {
          this.addMediaVisible = false
        })
    },
    getItemMedia () {
      const form = new FormData()

      form.append('company', this.$store.state.auth.user.company)
      form.append('store', this.$store.state.dataStore.selectedStore.code)
      form.append('idx_media', this.idx_media)
      form.append('timezone', this.$store.state.dataStore.timezone)
      form.append('type', 'item')

      this.$utils
        .callAxiosWithBody(
          dicamoApi.requests.getMediaItem.method,
          dicamoApi.requests.getMediaItem.url,
          form
        )
        .then((res) => {
          if (this.isPlaylist) {
            if (this.idx_media === '') {
              this.cancelEditMedia()
              this.$emit('fireResetMedia')
              EventBus.$emit('messageAlert', this.$t('No media selected.'))
              return
            } else if (this.mediaType === '') {
              this.cancelEditMedia()
              this.$emit('fireResetMedia')
              EventBus.$emit('messageAlert', this.$t('Invalid media data.'))
              return
            }
          }
          if (res.data.data.length === 0) {
            this.cancelEditMedia()
            this.$emit('fireResetMedia')
            EventBus.$emit('messageAlert', this.$t('Invalid media data.'))
          }

          this.formData = res.data.data
          this.formData.explain = res.data.data.description
          this.selectCategory = res.data.data.category
          this.filename = res.data.data.file_name
          // this.mediaDataList = this.convertProductsDate(res.data.articleList)
          // this.totalProducts = this.products.length
          // this.pageInfoText = commons.getPageInfoText(res.headers)
          // this.totalPages = Number(res.headers['x-totalpages'])
          // this.selected = []
        })
        .catch((error) => {
          this.mediaDataList = []
          // this.totalMedia = this.mediaDataList[0].length
          EventBus.$emit('messageAlert', this.$t('something went wrong.'))
          console.debug(`Could not find any medias. error: ${error}`)
        })
    },
    async editItemMedia (duration) {
      // const loader = this.$loading.show({
      //   container: this.fullPage ? null : this.$refs.formContainer,
      //   canCancel: false
      // })
      this.isLoading = true

      const form = new FormData()

      form.append('company', this.$store.state.auth.user.company)
      form.append('store', this.$store.state.dataStore.selectedStore.code)
      form.append('idx_media', this.idx_media)
      form.append('media_name', this.formData.media_name)
      form.append('category', this.formData.category)
      form.append('media_file', this.uploadFile)
      form.append('explain', this.formData.explain)
      form.append('timezone', this.$store.state.dataStore.timezone)
      form.append('duration', duration < 0 ? this.formData.duration : duration)
      form.append('type', 'item')

      this.$utils
        .callAxiosWithBody(
          dicamoApi.requests.editMediaItem.method,
          dicamoApi.requests.editMediaItem.url,
          form
        )
        .then((res) => {
          if (res.data.result) {
            this.isLoading = false
            this.$emit('fireResetMedia')
            this.cancelEditMedia()
            EventBus.$emit('messageAlert', this.$t('SUCCESS'))
          } else {
            this.isLoading = false
            this.$emit('fireResetMedia')
            this.cancelEditMedia()
            EventBus.$emit('messageAlert', this.$t(res.data.message))
          }
        })
        .catch((error) => {
          const result = error.response.data
          this.isLoading = false
          if (result.responseMessage) {
            EventBus.$emit('messageAlert', this.$t(result.responseMessage))
          } else {
            EventBus.$emit('messageAlert', this.$t('Failed to update'))
          }
          this.cancelEditMedia()
        })
    }
  },
  watch: {
    editMediaVisible: function () {
      if (this.editMediaVisible) {
        this.getItemMedia()
        this.getCategory()
      }
    },
    selectCategory: {
      handler () {
        this.formData.category = this.selectCategory
      }
    }
  }
}
</script>

<style scoped>
.mediafileClass{
  border-collapse: collapse;
    border-color: darkgray;
    border-style: solid;
    border-width: 1px;
    border-radius: 4px;
}
.tdCategoryClass{
  display: flex;
  margin-top: 20px;
}
.tdMediaClass{
  display: flex;
  margin-top: 5px;
}
.tdExplainClass{
  display: flex !important;
  margin-top: 36px !important;
}
/* Scroll bar stylings */
::v-deep ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  /* Track */
  ::v-deep ::-webkit-scrollbar-track {
    background: var(--lightestgrey);
  }

  /* Handle */
  ::v-deep ::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 5px;
  }

  /* Handle on hover */
  ::v-deep ::-webkit-scrollbar-thumb:hover {
    background: #c1c1c1;
  }
  ::v-deep .v-data-table__wrapper::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 5px;
    }
</style>
